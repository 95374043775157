
import styles from './Card.module.scss'
import React, {DetailedHTMLProps, HTMLAttributes} from "react";
import {Person} from "@/types/person";
import {InteractionStats} from "@/types/interactionStats";
import Mensenlinq from "@/Atoms/Icons/Mensenlinq";
import Pen from "@/Atoms/Icons/Pen";
import Flower from "@/Atoms/Icons/Flower";
import Candle from "@/Atoms/Icons/Candle";
import Birth from "@/Atoms/Icons/Birth";
import Deceased from "@/Atoms/Icons/Deceased";
import moment from "moment/moment";
import Panel from "@/Atoms/Panel";

interface CardProps extends DetailedHTMLProps<HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  person: Person,
  stats: InteractionStats,
  isOnline?: boolean,
  isObituary?: boolean,
  baseUrl?: (person: Person) => string,
}

export default function ObituaryCard(
  {
    className,
    person,
    stats,
    baseUrl,
    isOnline,
    isObituary,
    ...props
  }: CardProps) {

  const publishDate = null;//person.publish_from ? moment(person.publish_from).format(dateTimeFormat) : null

  let obituaryUrl = baseUrl ? baseUrl(person) : `/overlijdensberichten/${person.slug}`


  const {name, birth, deceased, papers, avatar} = person
  return <Panel className={[styles.card, className].join(' ')} {...props} href={obituaryUrl}>
    <div className={styles.avatar}>
      {avatar && avatar.url ? <img src={avatar.url} alt={''}/> : <Mensenlinq />}
    </div>
    {(typeof isOnline !== 'undefined') && <div className={styles.publishState}>{isOnline
      ? <>
        {/*<i className="fa-solid fa-eye text-green-500"></i>*/}
      </>
      : <span className={'hover'} data-title={`Zichtbaar vanaf ${publishDate}`}><i className="fa-solid fa-eye-slash primary"></i></span>
    }</div>}
    {isObituary && <div className={styles.isObituary}><div>Herdenkingspagina</div></div>
      }
      <div className={styles.content}>
      <div className={styles.title}>{name}</div>
      {(birth) && (
        <div className={styles.meta}><Birth />{birth}</div>
      )}
      {(deceased) && (
        <div className={styles.meta}><Deceased />{deceased}</div>
      )}
      <div className={styles.meta}>{papers.map(paper => paper.title).join(', ')}</div>
    </div>
    <ul className={styles.stats}>
      <li><Pen />{stats.condolences}</li>
      <li><Flower /> {stats.flowers}</li>
      <li><Candle /> {stats.candles}</li>
    </ul>

  </Panel>
}
